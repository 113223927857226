//src/frontend/Languages.js
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Languages.css';

const Languages = () => {
  const [languages, setLanguages] = useState([]);
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [newLanguageData, setNewLanguageData] = useState({ name: '', nativeName: '', code: '' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    filterAndSortLanguages();
  }, [languages, searchTerm, sortOrder]);

  const fetchLanguages = async () => {
    try {
      const response = await axios.get('/api/languages');
      setLanguages(response.data);
    } catch (err) {
      setError(`Error fetching data: ${err.message}`);
    }
  };

  const filterAndSortLanguages = () => {
    let filtered = languages.filter(
      (language) =>
        language.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        language.nativeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        language.code.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortOrder === 'asc') {
      filtered = filtered.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      filtered = filtered.sort((a, b) => b.name.localeCompare(a.name));
    }

    setFilteredLanguages(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleCellClick = (value) => {
    setModalContent(value);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedLanguage({});
    setNewLanguageData({ name: '', nativeName: '', code: '' });
  };

  const openEditModal = (language) => {
    setEditMode(true);
    setSelectedLanguage(language);
    setNewLanguageData({ name: language.name, nativeName: language.nativeName, code: language.code });
    setShowModal(true);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/languages/${selectedLanguage._id}`, newLanguageData);
      fetchLanguages();
      closeModal();
    } catch (err) {
      setError(`Error updating language: ${err.message}`);
    }
  };

  const openAddModal = () => {
    setEditMode(false);
    setSelectedLanguage({});
    setNewLanguageData({ name: '', nativeName: '', code: '' });
    setShowModal(true);
  };

  const handleAddNew = async () => {
    try {
      await axios.post('/api/languages', newLanguageData);
      fetchLanguages();
      closeModal();
    } catch (err) {
      setError(`Error adding language: ${err.message}`);
    }
  };

  const openDeleteDialog = (language) => {
    setSelectedLanguage(language);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedLanguage({});
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/languages/${selectedLanguage._id}`);
      fetchLanguages();
      closeDeleteDialog();
    } catch (err) {
      setError(`Error deleting language: ${err.message}`);
    }
  };

  const handleMenuClick = (event, language) => {
    setAnchorEl(event.currentTarget);
    setSelectedLanguage(language);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedLanguage({});
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box className="languages" p={2}>
      <Typography variant="h4" gutterBottom>
        Languages
      </Typography>

      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField
          placeholder="Search languages..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" color="primary" onClick={openAddModal}>
          Add New Language
        </Button>
        <Button variant="contained" onClick={toggleSortOrder}>
          Sort: {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Native Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLanguages.map((language, index) => (
              <TableRow key={index}>
                <TableCell>{language.name}</TableCell>
                <TableCell>{language.nativeName}</TableCell>
                <TableCell>{language.code}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleMenuClick(event, language)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => openEditModal(selectedLanguage)}>Edit</MenuItem>
                    <MenuItem onClick={() => openDeleteDialog(selectedLanguage)}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={showModal} onClose={closeModal}>
        <Box
          className="modal-content"
          style={{
            backgroundColor: '#fefefe',
            margin: 'auto',
            padding: '20px',
            border: '1px solid #888',
            width: '80%',
            maxWidth: '800px',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            maxHeight: '80%',
          }}
        >
          <Typography variant="h6">{editMode ? 'Edit Language' : 'Add New Language'}</Typography>
          <TextField
            label="Name"
            value={newLanguageData.name}
            onChange={(e) => setNewLanguageData({ ...newLanguageData, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Native Name"
            value={newLanguageData.nativeName}
            onChange={(e) => setNewLanguageData({ ...newLanguageData, nativeName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code"
            value={newLanguageData.code}
            onChange={(e) => setNewLanguageData({ ...newLanguageData, code: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={editMode ? handleUpdate : handleAddNew}>
              {editMode ? 'Update' : 'Add'}
            </Button>
            <Button variant="outlined" onClick={closeModal} style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this language?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Languages;

