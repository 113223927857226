//src/frontend/Login.js
import { Box, Button, Container, Typography } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { auth } from './firebaseConfig';
import './Login.css';

const Login = ({ setAuth }) => {
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Get ID token from Firebase
      const idToken = await user.getIdToken();

      // Send the token to the backend for verification
      const response = await fetch('/api/auth/google-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });

      const data = await response.json();

      if (data.success) {
        setAuth(true);
        navigate('/');
      } else {
        alert('Only @juricon.ai email addresses are allowed.');
      }
    } catch (error) {
      console.error('Error during Google login:', error);
      alert('An error occurred during login.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <img src={logo} alt="Logo" className="login-logo" />
        <Typography variant="h5" component="h1" gutterBottom>
          Welcome to JuriconAI Reporting
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoogleLogin}
          fullWidth
        >
          Sign in with Google
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
