//src/frontend/PredictionCharts.js
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const PredictionCharts = () => {
  const { endpoint } = useParams();
  const [chartData, setChartData] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [pastPeriod, setPastPeriod] = useState('3');
  const [predictionPeriod, setPredictionPeriod] = useState('3');

  useEffect(() => {
    fetchDataAndGeneratePredictions();
  }, [endpoint, pastPeriod, predictionPeriod]);

  const fetchDataAndGeneratePredictions = async () => {
    setLoading(true);
    setError('');
    try {
      const startDate = calculateStartDate(pastPeriod);
      const response = await axios.get(`/api/${endpoint}`);
      const data = response.data
        .map(item => ({
          userId: item._id,
          createdAt: new Date(item.createdAt),
          userType: item.userType,
        }))
        .filter(item => item.createdAt >= startDate);

      const aggregatedData = aggregateDataWeekly(data);
      setChartData(aggregatedData);

      const predictionResponse = await axios.post('/api/generate-prediction', {
        data: aggregatedData,
        pastPeriod,
        predictionPeriod,
      });

      const aggregatedPredictions = aggregateDataWeekly(predictionResponse.data.predictedData);
      setPredictions(aggregatedPredictions);
      setLoading(false);
    } catch (err) {
      setError(`Error fetching data or generating predictions: ${err.message}`);
      setLoading(false);
    }
  };

  const calculateStartDate = (months) => {
    return new Date(new Date().setMonth(new Date().getMonth() - parseInt(months)));
  };

  const aggregateDataWeekly = (data) => {
    const weeklyData = {};
    data.forEach(item => {
      const week = getWeek(item.createdAt);
      if (!weeklyData[week]) {
        weeklyData[week] = { count: 0, weekStart: week };
      }
      weeklyData[week].count += 1;
    });
    return Object.values(weeklyData);
  };

  const getWeek = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay());
    return startOfWeek.toISOString().split('T')[0];
  };

  const handlePredictClick = () => {
    fetchDataAndGeneratePredictions();
  };

  const combinedData = [
    ...chartData.map(item => ({ ...item, type: 'existing' })),
    ...predictions.map(item => ({ ...item, type: 'predicted' })),
  ];

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Predictions for {endpoint.charAt(0).toUpperCase() + endpoint.slice(1)}
      </Typography>

      <Box display="flex" gap={2} mb={3}>
        <FormControl variant="outlined">
          <InputLabel id="past-period-label">Past Period</InputLabel>
          <Select
            labelId="past-period-label"
            value={pastPeriod}
            onChange={(e) => setPastPeriod(e.target.value)}
            label="Past Period"
          >
            <MenuItem value="3">Last 3 Months</MenuItem>
            <MenuItem value="6">Last 6 Months</MenuItem>
            <MenuItem value="12">Last 12 Months</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined">
          <InputLabel id="prediction-period-label">Prediction Period</InputLabel>
          <Select
            labelId="prediction-period-label"
            value={predictionPeriod}
            onChange={(e) => setPredictionPeriod(e.target.value)}
            label="Prediction Period"
          >
            <MenuItem value="3">Next 3 Months</MenuItem>
            <MenuItem value="6">Next 6 Months</MenuItem>
            <MenuItem value="12">Next 12 Months</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handlePredictClick}
          disabled={loading}
        >
          Generate Prediction
        </Button>
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      )}

      {error && <Typography color="error">{error}</Typography>}

      {!loading && combinedData.length > 0 && (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={combinedData}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="weekStart" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="count" stroke="#8884d8" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="count" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      )}

      {!loading && combinedData.length === 0 && (
        <Typography variant="body1">
          No data available.
        </Typography>
      )}
    </Box>
  );
}

export default PredictionCharts;
