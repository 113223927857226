//src/frontend/HamburgerMenu.js
import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import './HamburgerMenu.css';

const HamburgerMenu = ({ setAuth }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    setAuth(false);
    navigate('/login');
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="hamburger-menu-container">
      <img src={logo} alt="Logo" className="menu-logo" />
      <Menu right>
        <a onClick={() => navigateTo('/')}>Dashboard</a>
        <a onClick={() => navigateTo('/practice-areas')}>Practice Areas</a>
        <a onClick={() => navigateTo('/languages')}>Languages</a>
        <a onClick={() => navigateTo('/schools')}>Schools</a>
        <a onClick={() => navigateTo('/users-management')}>User Management</a>
        <a onClick={handleLogout}>Logout</a>
      </Menu>
    </div>
  );
};

export default HamburgerMenu;