//src/frontend/SEOContentOptimizer.js
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const SEOContentOptimizer = ({ selectedUser, fieldToOptimize, onClose }) => {
  const [content, setContent] = useState('');
  const [keywords, setKeywords] = useState('');
  const [suggestedKeywords, setSuggestedKeywords] = useState('');
  const [tone, setTone] = useState('');
  const [optimizedContent, setOptimizedContent] = useState('');

  useEffect(() => {
    if (selectedUser && fieldToOptimize) {
      setContent(selectedUser[fieldToOptimize]);
    }
  }, [selectedUser, fieldToOptimize]);

  const handleSuggestKeywords = async () => {
    if (!content) {
      alert('Please ensure the content field has content.');
      return;
    }

    try {
      const response = await axios.post('/api/suggest-keywords', {
        text: content,
      });

      setSuggestedKeywords(response.data.keywords.join(', '));
    } catch (error) {
      console.error('Error suggesting keywords:', error);
    }
  };

  const handleOptimize = async () => {
    if (!content) {
      alert('Please ensure the content field has content.');
      return;
    }

    try {
      const response = await axios.post('/api/rewrite-content', {
        content,
        keywords: keywords || suggestedKeywords,
        tone,
      });

      setOptimizedContent(response.data.optimizedContent);
    } catch (error) {
      console.error('Error optimizing content:', error);
    }
  };

  const handleSave = async () => {
    if (!selectedUser || !fieldToOptimize) {
      alert('No user or field selected.');
      return;
    }

    try {
      // Save the optimized content if it exists, otherwise save the original content
      const contentToSave = optimizedContent || content;
      const updatedData = {
        ...selectedUser,
        [fieldToOptimize]: contentToSave,
      };

      await axios.put(`/api/users/${selectedUser._id}`, updatedData);

      alert('User data updated successfully!');
      onClose();
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Optimize Content</Typography>

      <TextField
        label="Keywords"
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Suggested Keywords"
        value={suggestedKeywords}
        onChange={(e) => setSuggestedKeywords(e.target.value)}
        fullWidth
        margin="normal"
        disabled
      />
      <Button variant="outlined" color="primary" onClick={handleSuggestKeywords} style={{ marginBottom: '10px' }}>
        Suggest Keywords
      </Button>

      <TextField
        label="Tone"
        value={tone}
        onChange={(e) => setTone(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Typography variant="subtitle1">Content to Optimize</Typography>
      <TextField
        value={content}
        onChange={(e) => setContent(e.target.value)}
        multiline
        fullWidth
        margin="normal"
      />

      {optimizedContent && (
        <>
          <Typography variant="subtitle1" color="primary">Optimized Content</Typography>
          <TextField
            value={optimizedContent}
            multiline
            fullWidth
            margin="normal"
            disabled
          />
        </>
      )}

      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleOptimize}>
          Optimize Content
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSave} style={{ marginLeft: '10px' }}>
          Save Optimized Content
        </Button>
      </Box>
    </Box>
  );
};

export default SEOContentOptimizer;
