//src/App.js
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ChartPage from './frontend/ChartPage';
import DataTable from './frontend/DataTable';
import EmailSettings from './frontend/EmailSettings';
import EmailTemplatePage from './frontend/EmailTemplatePage'; // Assuming you have EmailTemplatePage component
import HamburgerMenu from './frontend/HamburgerMenu';
import LandingPage from './frontend/LandingPage';
import Languages from './frontend/Languages';
import LinkedInLoginPage from './frontend/LNlogin';
import LinkedInSuccessPage from './frontend/LNsuccess';
import Login from './frontend/Login';
import PracticeAreas from './frontend/PracticeAreas';
import PracticeAreasPriority from './frontend/PracticeAreasPriority';
import PredictionCharts from './frontend/PredictionCharts';
import ProfileClicksTable from './frontend/ProfileClicksTable';
import Schools from './frontend/Schools';
import SEOContentOptimizer from './frontend/SEOContentOptimizer';
import UsersManagement from './frontend/UsersManagement';

const queryClient = new QueryClient();

const PrivateRoute = ({ auth, children }) => {
  return auth ? children : <Navigate to="/login" replace />;
};

const App = () => {
  const [auth, setAuth] = useState(false); // For simplicity, using state to manage auth. In practice, you might want to use a context or global state for auth.

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="app">
          {auth && <HamburgerMenu setAuth={setAuth} />}
          <Routes>
            <Route path="/login" element={<Login setAuth={setAuth} />} />
            <Route
              path="/"
              element={
                <PrivateRoute auth={auth}>
                  <LandingPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/users-data"
              element={
                <PrivateRoute auth={auth}>
                  <DataTable endpoint="users" />
                </PrivateRoute>
              }
            />
            <Route
              path="/searches-data"
              element={
                <PrivateRoute auth={auth}>
                  <DataTable endpoint="searches" />
                </PrivateRoute>
              }
            />
            <Route
              path="/inquiries-data"
              element={
                <PrivateRoute auth={auth}>
                  <DataTable endpoint="inquiries" />
                </PrivateRoute>
              }
            />
            <Route
              path="/email-templates"
              element={
                <PrivateRoute auth={auth}>
                  <EmailTemplatePage /> {/* Updated the component */}
                </PrivateRoute>
              }
            />
            <Route
              path="/users-chart"
              element={
                <PrivateRoute auth={auth}>
                  <ChartPage endpoint="users" />
                </PrivateRoute>
              }
            />
            <Route
              path="/searches-chart"
              element={
                <PrivateRoute auth={auth}>
                  <ChartPage endpoint="searches" />
                </PrivateRoute>
              }
            />
            <Route
              path="/inquiries-chart"
              element={
                <PrivateRoute auth={auth}>
                  <ChartPage endpoint="inquiries" />
                </PrivateRoute>
              }
            />
            <Route
              path="/practice-areas"
              element={
                <PrivateRoute auth={auth}>
                  <PracticeAreas />
                </PrivateRoute>
              }
            />
            <Route
              path="/languages"
              element={
                <PrivateRoute auth={auth}>
                  <Languages />
                </PrivateRoute>
              }
            />
            <Route
              path="/schools"
              element={
                <PrivateRoute auth={auth}>
                  <Schools />
                </PrivateRoute>
              }
            />
            <Route
              path="/users-management"
              element={
                <PrivateRoute auth={auth}>
                  <UsersManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile-clicks"
              element={
                <PrivateRoute auth={auth}>
                  <ProfileClicksTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/seo-optimizer"
              element={
                <PrivateRoute auth={auth}>
                  <SEOContentOptimizer />
                </PrivateRoute>
              }
            />
            <Route
              path="/prediction-charts/:endpoint"
              element={
                <PrivateRoute auth={auth}>
                  <PredictionCharts />
                </PrivateRoute>
              }
            />
            <Route
              path="/practice-areas-priority"
              element={
                <PrivateRoute auth={auth}>
                  <PracticeAreasPriority />
                </PrivateRoute>
              }
            />
            <Route
              path="/linkedin-login"
              element={<LinkedInLoginPage />}
            />
            <Route
              path="/linkedin-success"
              element={<LinkedInSuccessPage />}
            />
            <Route
              path="/email-settings"
              element={<EmailSettings />}
            />
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
};

export default App;