//src/frontend/ProfileClicksTable.js
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const ProfileClicksTable = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('firstName');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchProfileClicks = async () => {
            try {
                const response = await axios.get('/api/profile-clicks');
                setData(response.data);
            } catch (err) {
                setError(`Error fetching profile clicks: ${err.message}`);
            }
        };

        fetchProfileClicks();
    }, []);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = data.filter(
        (row) =>
            row.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedData = filteredData.sort((a, b) => {
        if (orderBy === 'latestClickDate') {
            const dateA = a[orderBy] ? new Date(a[orderBy]) : new Date(0); // Use Epoch if date is invalid
            const dateB = b[orderBy] ? new Date(b[orderBy]) : new Date(0); // Use Epoch if date is invalid
            return order === 'asc' ? dateA - dateB : dateB - dateA;
        }
        if (a[orderBy] < b[orderBy]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box p={2}>
            <Typography variant="h4" gutterBottom>
                Profile Clicks by Lawyer
            </Typography>
            <TextField
                label="Search by Name or Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'lawyerId'}
                                    direction={orderBy === 'lawyerId' ? order : 'asc'}
                                    onClick={() => handleRequestSort('lawyerId')}
                                >
                                    Lawyer ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'firstName'}
                                    direction={orderBy === 'firstName' ? order : 'asc'}
                                    onClick={() => handleRequestSort('firstName')}
                                >
                                    First Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'lastName'}
                                    direction={orderBy === 'lastName' ? order : 'asc'}
                                    onClick={() => handleRequestSort('lastName')}
                                >
                                    Last Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'email'}
                                    direction={orderBy === 'email' ? order : 'asc'}
                                    onClick={() => handleRequestSort('email')}
                                >
                                    Email
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'totalClicks'}
                                    direction={orderBy === 'totalClicks' ? order : 'asc'}
                                    onClick={() => handleRequestSort('totalClicks')}
                                >
                                    Total Clicks
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'latestClickDate'}
                                    direction={orderBy === 'latestClickDate' ? order : 'asc'}
                                    onClick={() => handleRequestSort('latestClickDate')}
                                >
                                    Latest Click Date
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row) => (
                            <TableRow key={row.lawyerId}>
                                <TableCell>{row.lawyerId}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.totalClicks}</TableCell>
                                <TableCell>{row.latestClickDate ? new Date(row.latestClickDate).toLocaleString() : 'N/A'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ProfileClicksTable;