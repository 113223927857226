//src/frontend/LandingPage.js
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const fetchData = (endpoint) => axios.get(`/api/${endpoint}`).then((res) => res.data);
const fetchGrowth = (type, period) =>
  axios
    .get(`/api/growth/${type}?period=${period}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(
        'Error fetching growth data:',
        error.response ? error.response.data : error.message
      );
    });

const LandingPage = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItem, setMenuItem] = useState('');

  const endpoints = [
    'users',
    'searches',
    'inquiries',
    'languages',
    'categories',
    'schools',
    ['growth', 'users', 'week'],
    ['growth', 'users', 'month'],
    ['growth', 'searches', 'week'],
    ['growth', 'searches', 'month'],
    ['growth', 'inquiries', 'week'],
    ['growth', 'inquiries', 'month'],
  ];

  const results = useQueries({
    queries: endpoints.map((endpoint) => ({
      queryKey: Array.isArray(endpoint) ? endpoint : [endpoint],
      queryFn: Array.isArray(endpoint) ? () => fetchGrowth(endpoint[1], endpoint[2]) : () => fetchData(endpoint),
      staleTime: 900000, // 15 minutes
      refetchOnWindowFocus: false, // Prevent refetching when window regains focus
    })),
  });

  const isLoading = results.some((result) => result.isLoading);
  const isError = results.some((result) => result.isError);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError) return <Typography color="error">Error fetching data</Typography>;

  const data = results.slice(0, 6).map((result) => result.data);
  const growthData = results.slice(6).map((result) => result.data);

  console.log('Data:', data);
  console.log('Growth Data:', growthData);

  // Add check for data[4] to avoid undefined error
  const totalSubcategories = Array.isArray(data[4])
    ? data[4].reduce((total, category) => total + (category.subcategories?.length || 0), 0)
    : 0;

  const counts = {
    users: Array.isArray(data[0]) ? data[0].length : 0,
    searches: Array.isArray(data[1]) ? data[1].length : 0,
    inquiries: Array.isArray(data[2]) ? data[2].length : 0,
    languages: Array.isArray(data[3]) ? data[3].length : 0,
    categories: Array.isArray(data[4]) ? data[4].length : 0,
    subcategories: totalSubcategories,
    schools: Array.isArray(data[5]) ? data[5].length : 0,
  };

  const growthRates = {
    users: {
      wow: growthData[0]?.growthRate || 0,
      mom: growthData[1]?.growthRate || 0,
    },
    searches: {
      wow: growthData[2]?.growthRate || 0,
      mom: growthData[3]?.growthRate || 0,
    },
    inquiries: {
      wow: growthData[4]?.growthRate || 0,
      mom: growthData[5]?.growthRate || 0,
    },
  };

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuItem('');
  };

  const navigateTo = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const renderGrowthRate = (wow, mom) => (
    <>
      <Typography variant="body2" color="primary">
        WoW Growth: {isNaN(wow) ? 'N/A' : `${wow.toFixed(2)}%`}
      </Typography>
      <Typography variant="body2" color="secondary">
        MoM Growth: {isNaN(mom) ? 'N/A' : `${mom.toFixed(2)}%`}
      </Typography>
    </>
  );

  return (
    <Box className="landing-page" p={2}>
      <Typography variant="h4" gutterBottom>
        Welcome to the Reporting Dashboard
      </Typography>

      {/* Email Settings Button */}
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={() => navigateTo('/email-settings')}>
          Email Settings
        </Button>
      </Box>

      <Grid container spacing={3}>
        {['users', 'searches', 'inquiries'].map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item}>
            <Card className="dashboard-card">
              <CardContent>
                <Typography variant="h5">{item.charAt(0).toUpperCase() + item.slice(1)}</Typography>
                <Typography variant="h2">{counts[item]}</Typography>
                {renderGrowthRate(growthRates[item].wow, growthRates[item].mom)}
                <IconButton onClick={(event) => handleMenuOpen(event, item)}>
                  <MoreVertIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4}>
          <Card className="dashboard-card" onClick={() => navigateTo('/practice-areas')}>
            <CardContent>
              <Typography variant="h5">Practice Areas</Typography>
              <Typography variant="h6">Categories: {counts.categories}</Typography>
              <Typography variant="h6">Subcategories: {counts.subcategories}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className="dashboard-card" onClick={() => navigateTo('/languages')}>
            <CardContent>
              <Typography variant="h5">Languages</Typography>
              <Typography variant="h2">{counts.languages}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className="dashboard-card" onClick={() => navigateTo('/schools')}>
            <CardContent>
              <Typography variant="h5">Schools</Typography>
              <Typography variant="h2">{counts.schools}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className="dashboard-card" onClick={() => navigateTo('/users-management')}>
            <CardContent>
              <Typography variant="h5">User Management</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className="dashboard-card" onClick={() => navigateTo('/practice-areas-priority')}>
            <CardContent>
              <Typography variant="h5">Practice Areas Priority</Typography>
              <Typography variant="body1">Analyze search data for practice areas</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className="dashboard-card" onClick={() => navigateTo('/email-templates')}>
            <CardContent>
              <Typography variant="h5">Email Templates</Typography>
              <Typography variant="body1">Manage Email Templates and Triggers</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className="dashboard-card" onClick={() => navigateTo('/profile-clicks')}>
            <CardContent>
              <Typography variant="h5">Profile Clicks</Typography>
              <Typography variant="body1">Analyze Lawyer Profile Clicks</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => navigateTo(`/${menuItem}-data`)}>Data</MenuItem>
          <MenuItem onClick={() => navigateTo(`/${menuItem}-chart`)}>Chart</MenuItem>
        </Menu>
      </Grid>
    </Box>
  );
};

export default LandingPage;