import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LinkedInSuccessPage = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('/linkedin-success')
      .then(response => {
        setUser(response.data);
        // Optionally, redirect after a delay
        setTimeout(() => {
          navigate('/'); // Redirect to home or dashboard
        }, 3000);
      })
      .catch(error => {
        console.error('Error fetching LinkedIn profile:', error);
        setError('Failed to load LinkedIn profile. Please try again.');
      });
  }, [navigate]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Welcome, {user.displayName}</h1>
      <p>Email: {user.emails[0].value}</p>
      <img src={user.photos[0].value} alt="Profile" />
      {/* Optional: Add a logout or continue button */}
      <button onClick={() => navigate('/')}>Go to Dashboard</button>
    </div>
  );
};

export default LinkedInSuccessPage;