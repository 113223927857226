//src/frontend/firebaseConfig.js

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCrQO725z5408SpWmOsErF7_n8yPOE2LpA",
  authDomain: "juriconai-reporting.firebaseapp.com",
  projectId: "juriconai-reporting",
  storageBucket: "juriconai-reporting.appspot.com",
  messagingSenderId: "802628842763",
  appId: "1:802628842763:web:759cceb4408941a02f1e0e",
  measurementId: "G-EQV2H51YJG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize Firebase Auth
const db = getFirestore(app); // Initialize Firestore

// Export the required Firebase functionalities
export { analytics, auth, db };
