import React from 'react';

const LinkedInLoginPage = () => {
  const handleLogin = () => {
    window.location.href = '/auth/linkedin';
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Login with LinkedIn</h1>
      <button onClick={handleLogin}>Login with LinkedIn</button>
    </div>
  );
};

export default LinkedInLoginPage;