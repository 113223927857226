//src/frontend/PracticeAreas.js
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Menu,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // Hamburger menu icon
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './PracticeAreas.css';

const PracticeAreas = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editType, setEditType] = useState(''); // 'Category' or 'Subcategory'
  const [selectedItem, setSelectedItem] = useState({});
  const [newName, setNewName] = useState('');
  const [parentCategory, setParentCategory] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorElCategory, setAnchorElCategory] = useState(null); // State for Category menu
  const [anchorElSubcategory, setAnchorElSubcategory] = useState(null); // State for Subcategory menu

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/categories');
      if (Array.isArray(response.data)) {
        setData(response.data);
      } else {
        setError('Data is not an array');
      }
    } catch (err) {
      setError(`Error fetching data: ${err.message}`);
    }
  };

  const handleCellClick = (value) => {
    setModalContent(value);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditMode(false);
    setEditType('');
    setNewName('');
    setParentCategory('');
  };

  const openEditModal = (type, item) => {
    setEditMode(true);
    setEditType(type);
    setSelectedItem(item);
    setNewName(item.name || '');
    if (type === 'Subcategory') {
      setParentCategory(item.category_id);
    }
    setShowModal(true);
  };

  const handleUpdate = async () => {
    try {
      if (editType === 'Category') {
        await axios.put(`/api/categories/${selectedItem._id}`, { name: newName });
      } else if (editType === 'Subcategory') {
        await axios.put(`/api/subcategories/${selectedItem._id}`, { name: newName, category_id: parentCategory });
      }
      fetchData();
      closeModal();
    } catch (err) {
      setError(`Error updating data: ${err.message}`);
    }
  };

  const openAddModal = (type) => {
    setEditMode(true);
    setEditType(type);
    setSelectedItem({});
    setNewName('');
    setParentCategory('');
    setShowModal(true);
  };

  const handleAddNew = async () => {
    try {
      if (editType === 'Category') {
        await axios.post('/api/categories', { name: newName });
      } else if (editType === 'Subcategory') {
        await axios.post('/api/subcategories', { name: newName, category_id: parentCategory });
      }
      fetchData();
      closeModal();
    } catch (err) {
      setError(`Error adding data: ${err.message}`);
    }
  };

  const openDeleteDialog = (type, item) => {
    setEditType(type);
    setSelectedItem(item);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      if (editType === 'Category') {
        await axios.delete(`/api/categories/${selectedItem._id}`);
      } else if (editType === 'Subcategory') {
        await axios.delete(`/api/subcategories/${selectedItem._id}`);
      }
      fetchData();
      closeDeleteDialog();
    } catch (err) {
      setError(`Error deleting data: ${err.message}`);
    }
  };

  const handleMenuClickCategory = (event) => {
    setAnchorElCategory(event.currentTarget);
  };

  const handleMenuClickSubcategory = (event) => {
    setAnchorElSubcategory(event.currentTarget);
  };

  const handleMenuCloseCategory = () => {
    setAnchorElCategory(null);
  };

  const handleMenuCloseSubcategory = () => {
    setAnchorElSubcategory(null);
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box className="practice-areas" p={2}>
      <Typography variant="h4" gutterBottom>Practice Areas</Typography>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={() => openAddModal('Category')} style={{ marginRight: '10px' }}>
          Add New Category
        </Button>
        <Button variant="contained" color="primary" onClick={() => openAddModal('Subcategory')}>
          Add New Subcategory
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Subcategories</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <List>
                    {item.subcategories.map((sub, subIndex) => (
                      <ListItem key={subIndex}>
                        <ListItemText primary={sub.name} />
                        <IconButton onClick={handleMenuClickSubcategory}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorElSubcategory}
                          open={Boolean(anchorElSubcategory)}
                          onClose={handleMenuCloseSubcategory}
                        >
                          <MenuItem onClick={() => openEditModal('Subcategory', sub)}>Edit</MenuItem>
                          <MenuItem onClick={() => openDeleteDialog('Subcategory', sub)}>Delete</MenuItem>
                        </Menu>
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell>
                  <IconButton onClick={handleMenuClickCategory}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElCategory}
                    open={Boolean(anchorElCategory)}
                    onClose={handleMenuCloseCategory}
                  >
                    <MenuItem onClick={() => openEditModal('Category', item)}>Edit</MenuItem>
                    <MenuItem onClick={() => openDeleteDialog('Category', item)}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={showModal} onClose={closeModal}>
        <Box
          className="modal-content"
          style={{
            backgroundColor: '#fefefe',
            margin: 'auto',
            padding: '20px',
            border: '1px solid #888',
            width: '80%',
            maxWidth: '800px',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            maxHeight: '80%'
          }}
        >
          <Typography variant="h6">{editMode ? `Edit ${editType}` : 'Details'}</Typography>
          {editMode ? (
            <>
              <TextField
                label={`${editType} Name`}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                fullWidth
              />
              {editType === 'Subcategory' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="category-label">Parent Category</InputLabel>
                  <Select
                    labelId="category-label"
                    value={parentCategory}
                    onChange={(e) => setParentCategory(e.target.value)}
                  >
                    {data.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Box mt={2}>
                <Button variant="contained" color="primary" onClick={selectedItem._id ? handleUpdate : handleAddNew}>
                  {selectedItem._id ? 'Update' : 'Add'}
                </Button>
                <Button variant="outlined" onClick={
                  closeModal} style={{ marginLeft: '10px' }}>
                  Cancel
                </Button>
              </Box>
            </>
          ) : (
            <pre>{modalContent}</pre>
          )}
        </Box>
      </Modal>

      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {editType.toLowerCase()}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PracticeAreas;
