//src/frontend/DataTable.js
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import './DataTable.css';

const DataTable = ({ endpoint }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const defaultColumns = {
    users: ['createdAt', 'firstName', 'lastName', 'postalCode', 'email', 'userType'],
    searches: ['createdAt', 'text', 'openAiResponse', 'user'],
    inquiries: ['createdAt', 'title', 'provider', 'userEmail']
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/${endpoint}`);
        if (Array.isArray(response.data)) {
          setData(response.data);
          setFilteredData(response.data);

          const initialHiddenColumns = Object.keys(response.data[0]).filter(
            (key) => !defaultColumns[endpoint].includes(key)
          );
          setHiddenColumns(initialHiddenColumns);
        } else {
          setError('Data is not an array');
        }
      } catch (err) {
        setError(`Error fetching data: ${err.message}`);
      }
    };

    fetchData();
  }, [endpoint]);

  useEffect(() => {
    let filtered = data;

    if (searchQuery) {
      filtered = filtered.filter(item =>
        Object.values(item).some(value =>
          String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    setFilteredData(filtered);
  }, [data, searchQuery]);

  const handleCellClick = (value) => {
    setModalContent(value);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleColumnToggle = (column) => {
    setHiddenColumns((prevHiddenColumns) =>
      prevHiddenColumns.includes(column)
        ? prevHiddenColumns.filter((col) => col !== column)
        : [...prevHiddenColumns, column]
    );
  };

  const downloadCSV = () => {
    const headers = Object.keys(data[0]).filter((key) => !hiddenColumns.includes(key));
    const rows = filteredData.map((row) =>
      headers.map((header) => (typeof row[header] === 'object' ? JSON.stringify(row[header]) : row[header]))
    );

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    rows.forEach((rowArray) => {
      csvContent += rowArray.join(',') + '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${endpoint}.csv`);
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const renderCellValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  };

  const columns = useMemo(
    () =>
      data.length > 0
        ? Object.keys(data[0]).map((key) => ({
            Header: key,
            accessor: key,
            Cell: ({ value }) => (
              <div onClick={() => handleCellClick(renderCellValue(value))}>
                {renderCellValue(value)}
              </div>
            ),
            sortType: 'basic'
          }))
        : [],
    [data]
  );

  const tableInstance = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        hiddenColumns: hiddenColumns,
        sortBy: [{ id: 'createdAt', desc: true }]
      }
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns
  } = tableInstance;

  useEffect(() => {
    allColumns.forEach((column) => {
      column.toggleHidden(hiddenColumns.includes(column.id));
    });
  }, [hiddenColumns, allColumns]);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box className="data-table" p={2}>
      <Typography variant="h4" gutterBottom>Data for {endpoint}</Typography>
      <Input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        margin="dense"
      />
      <Box mb={2}>
        {allColumns.map((column) => (
          <FormControlLabel
            key={column.id}
            control={
              <Checkbox
                checked={!hiddenColumns.includes(column.id)}
                onChange={() => handleColumnToggle(column.id)}
              />
            }
            label={column.Header}
          />
        ))}
      </Box>
      <Button variant="contained" color="primary" onClick={downloadCSV}>
        Download CSV
      </Button>
      <TableContainer component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) =>
                  !hiddenColumns.includes(column.id) ? (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                      <div
                        {...column.getResizerProps()}
                        className="resizer"
                      />
                    </TableCell>
                  ) : null
                )}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) =>
                    !hiddenColumns.includes(cell.column.id) ? (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {showModal && (
        <Modal open={showModal} onClose={closeModal}>
          <Box
            className="modal-content"
            style={{
              backgroundColor: '#fefefe',
              margin: 'auto',
              padding: '20px',
              border: '1px solid #888',
              width: '80%',
              maxWidth: '800px',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              overflow: 'auto',
              maxHeight: '80%'
            }}
          >
            <Typography variant="h6">Details</Typography>
            <Button onClick={closeModal} style={{ float: 'right' }}>Close</Button>
            <pre>{modalContent}</pre>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default DataTable;