import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    ExpandMore as ExpandMoreIcon,
    History as HistoryIcon
} from '@mui/icons-material';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Alert,
    Box,
    Button,
    Card, CardContent, CardHeader,
    Checkbox,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, FormControlLabel, FormGroup, FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Switch,
    Tab,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

const EmailTemplatePage = () => {
    const [templates, setTemplates] = useState([]);
    const [sequences, setSequences] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [emailingServicesUsers, setEmailingServicesUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentTestingTemplateId, setCurrentTestingTemplateId] = useState(null);
    const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
    const [emailHistory, setEmailHistory] = useState([]);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const triggerOptions = [
        'ON_SIGNUP',
        'AFTER_X_DAYS',
        '1_MIN_AFTER',
        '15_MIN_AFTER',
        '1_DAY_AFTER',
        '3_DAYS_AFTER',
        '7_DAYS_AFTER'
    ];

    const userTypeOptions = ['CUSTOMER', 'PROVIDER'];

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const { control: controlTemplate, handleSubmit: handleSubmitTemplate, reset: resetTemplate, watch: watchTemplate } = useForm({
        defaultValues: {
            name: '',
            subject: '',
            userType: '',
            trigger: '',
            triggerValue: 0,
            content: '',
        }
    });

    const { control: controlSequence, handleSubmit: handleSubmitSequence, reset: resetSequence, watch: watchSequence } = useForm({
        defaultValues: {
            name: '',
            description: '',
            userType: '',
            steps: [],
        }
    });

    const fetchTemplates = useCallback(async () => {
        try {
            const response = await axios.get(`/api/email-templates?page=${page}&limit=10`);
            console.log('Fetched templates:', response.data);
            setTemplates(response.data.templates || []);
            setTotalPages(response.data.totalPages || 1);
        } catch (err) {
            console.error('Error fetching email templates:', err);
            setSnackbar({ open: true, message: 'Failed to fetch email templates', severity: 'error' });
        }
    }, [page]);

    const fetchSequences = useCallback(async () => {
        try {
            const response = await axios.get('/api/email-sequences');
            setSequences(response.data);
        } catch (err) {
            console.error('Error fetching email sequences:', err);
            const errorMessage = err.response?.data?.message || err.message || 'An unknown error occurred';
            setSnackbar({ 
                open: true, 
                message: `Failed to fetch email sequences: ${errorMessage}`,
                severity: 'error' 
            });
            setSequences([]);
        }
    }, []);

    useEffect(() => {
        fetchTemplates();
        fetchSequences();
    }, [fetchTemplates, fetchSequences]);

    const handleSubmit = async (data, type) => {
        if (typeof data !== 'object' || typeof type !== 'string') {
            console.error('Invalid data or type in handleSubmit', { data, type });
            return;
        }
    
        try {
            const url = type === 'template' ? `/api/email-templates` : `/api/email-sequences`;
            const method = data.id ? 'put' : 'post';
            console.log(`Sending ${method.toUpperCase()} request to ${url} with data:`, data);
            const response = await axios[method](url + (data.id ? `/${data.id}` : ''), data);
            console.log(`Response from ${url}:`, response.data);
            setSnackbar({ open: true, message: `${type} saved successfully`, severity: 'success' });
            if (type === 'template') {
                resetTemplate();
                fetchTemplates();
            } else {
                resetSequence();
                fetchSequences();
            }
        } catch (err) {
            console.error(`Error saving ${type}:`, err);
            const errorMessage = err.response?.data?.message || err.message || 'An unknown error occurred';
            setSnackbar({ open: true, message: `Failed to save ${type}: ${errorMessage}`, severity: 'error' });
        }
    };

    const handleDelete = async (id, type) => {
        if (typeof id !== 'string' || typeof type !== 'string') {
            console.error('Invalid id or type in handleDelete', { id, type });
            return;
        }

        try {
            await axios.delete(`/api/${type}/${id}`);
            const capitalizedType = type.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            setSnackbar({ open: true, message: `${capitalizedType} deleted successfully`, severity: 'success' });
            type === 'email-templates' ? fetchTemplates() : fetchSequences();
        } catch (err) {
            console.error(`Error deleting ${type}:`, err);
            setSnackbar({ open: true, message: `Failed to delete ${type}`, severity: 'error' });
        }
    };

    const handleEdit = (data, type) => {
        console.log('Editing:', { data, type });
        type === 'template' ? resetTemplate(data) : resetSequence(data);
    };

    const handleToggleActive = async (id, isActive, type) => {
        if (typeof id !== 'string' || typeof isActive !== 'boolean' || typeof type !== 'string') {
            console.error('Invalid parameters in handleToggleActive', { id, isActive, type });
            return;
        }

        try {
            const url = type === 'template' ? `/api/email-templates/${id}/toggle-active` : `/api/email-sequences/${id}/toggle-active`;
            await axios.put(url, { isActive });
            setSnackbar({ open: true, message: `${type} ${isActive ? 'activated' : 'deactivated'} successfully`, severity: 'success' });
            type === 'template' ? fetchTemplates() : fetchSequences();
        } catch (err) {
            console.error(`Error toggling ${type} active status:`, err);
            setSnackbar({ open: true, message: `Failed to toggle ${type} status`, severity: 'error' });
        }
    };

    const handleOpenHistoryDialog = async (id, type) => {
        setHistoryLoading(true);
        setOpenHistoryDialog(true);
        try {
            const response = await axios.get(`/api/email-history?${type}Id=${id}`);
            console.log('Fetched email history:', response.data);
            setEmailHistory(response.data || []);
        } catch (error) {
            console.error('Error fetching email history:', error);
            setSnackbar({ open: true, message: 'Failed to fetch email history', severity: 'error' });
        }
        setHistoryLoading(false);
    };

    const handleUserSelectionChange = (userId) => {
        setSelectedUsers(prev => prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]);
    };

    const renderTemplateField = (name, label, options = null) => (
        <Grid item xs={12} key={name}>
            <Controller
                name={name}
                control={controlTemplate}
                rules={{ required: `${label} is required` }}
                render={({ field, fieldState: { error } }) => {
                    if (name === 'content') {
                        return (
                            <ReactQuill
                                theme="snow"
                                value={field.value}
                                onChange={field.onChange}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                                        [{size: []}],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{'list': 'ordered'}, {'list': 'bullet'}, 
                                         {'indent': '-1'}, {'indent': '+1'}],
                                        ['link', 'image'],
                                        ['clean']
                                    ],
                                }}
                            />
                        );
                    }
                    
                    if (options) {
                        return (
                            <FormControl fullWidth error={!!error}>
                                <InputLabel>{label}</InputLabel>
                                <Select {...field} label={label}>
                                    {options.map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                                {error && <FormLabel error>{error.message}</FormLabel>}
                            </FormControl>
                        );
                    }
                    
                    return (
                        <TextField
                            {...field}
                            label={label}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    );
                }}
            />
        </Grid>
    );

    const renderSequenceField = (name, label, multiline = false) => (
        <Grid item xs={12} key={name}>
            <Controller
                name={name}
                control={controlSequence}
                rules={{ required: `${label} is required` }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label={label}
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                        multiline={multiline}
                        rows={multiline ? 2 : 1}
                    />
                )}
            />
        </Grid>
    );

    const renderTemplates = () => {
        if (!Array.isArray(templates)) {
            console.error('Templates is not an array:', templates);
            return null;
        }
        return templates.map((template) => (
            <TableRow key={template._id}>
                <TableCell>{template.name || 'N/A'}</TableCell>
                <TableCell>{template.userType || 'N/A'}</TableCell>
                <TableCell>{template.trigger || 'N/A'}</TableCell>
                <TableCell>
                    <Switch
                        checked={Boolean(template.isActive)}
                        onChange={() => handleToggleActive(template._id, !template.isActive, 'template')}
                    />
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => handleEdit(template, 'template')}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleDelete(template._id, 'email-templates')}><DeleteIcon /></IconButton>
                    <IconButton onClick={() => handleOpenHistoryDialog(template._id, 'template')}><HistoryIcon /></IconButton>
                </TableCell>
            </TableRow>
        ));
    };

    const renderSequences = () => {
        if (!Array.isArray(sequences)) {
            console.error('Sequences is not an array:', sequences);
            return null;
        }
        return sequences.map((sequence) => (
            <TableRow key={sequence._id}>
                <TableCell>{sequence.name || 'N/A'}</TableCell>
                <TableCell>{sequence.userType || 'N/A'}</TableCell>
                <TableCell>{sequence.steps ? sequence.steps.length : 0}</TableCell>
                <TableCell>
                    <Switch
                        checked={Boolean(sequence.isActive)}
                        onChange={() => handleToggleActive(sequence._id, !sequence.isActive, 'sequence')}
                    />
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => handleEdit(sequence, 'sequence')}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleDelete(sequence._id, 'email-sequences')}><DeleteIcon /></IconButton>
                    <IconButton onClick={() => handleOpenHistoryDialog(sequence._id, 'sequence')}><HistoryIcon /></IconButton>
                </TableCell>
            </TableRow>
        ));
    };

    const renderSequenceStepForm = (index) => (
        <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Step {index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name={`steps.${index}.templateId`}
                            control={controlSequence}
                            rules={{ required: 'Template is required' }}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl fullWidth error={!!error}>
                                    <InputLabel>Template</InputLabel>
                                    <Select {...field} label="Template">
                                        {templates.map((template) => (
                                            <MenuItem key={template._id} value={template._id}>
                                                {template.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error && <FormLabel error>{error.message}</FormLabel>}
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name={`steps.${index}.delay`}
                            control={controlSequence}
                            rules={{ required: 'Delay is required', min: 1 }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    label="Delay"
                                    type="number"
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name={`steps.${index}.delayUnit`}
                            control={controlSequence}
                            rules={{ required: 'Delay unit is required' }}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl fullWidth error={!!error}>
                                    <InputLabel>Delay Unit</InputLabel>
                                    <Select {...field} label="Delay Unit">
                                        <MenuItem value="minutes">Minutes</MenuItem>
                                        <MenuItem value="hours">Hours</MenuItem>
                                        <MenuItem value="days">Days</MenuItem>
                                    </Select>
                                    {error && <FormLabel error>{error.message}</FormLabel>}
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                const steps = watchSequence('steps');
                                const newSteps = steps.filter((_, i) => i !== index);
                                resetSequence({ ...watchSequence(), steps: newSteps });
                            }}
                        >
                            Remove Step
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );

    const handleAddStep = () => {
        const steps = watchSequence('steps') || [];
        resetSequence({ 
            ...watchSequence(), 
            steps: [...steps, { templateId: '', delay: 1, delayUnit: 'days' }] 
        });
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="email management tabs">
                <Tab label="Templates" />
                <Tab label="Sequences" />
            </Tabs>

            {activeTab === 0 && (
                <Box>
                    <Card sx={{ mt: 2 }}>
                        <CardHeader title="Email Templates" subheader="Create and manage email templates" />
                        <CardContent>
                            <form onSubmit={handleSubmitTemplate((data) => handleSubmit(data, 'template'))}>
                                <Grid container spacing={2}>
                                    {renderTemplateField('name', 'Name')}
                                    {renderTemplateField('subject', 'Subject')}
                                    {renderTemplateField('userType', 'User Type', userTypeOptions)}
                                    {renderTemplateField('trigger', 'Trigger', triggerOptions)}
                                    {renderTemplateField('content', 'Content')}
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary">Save Template</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                    <Card sx={{ mt: 2 }}>
                        <CardHeader title="Template List" />
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>User Type</TableCell>
                                            <TableCell>Trigger</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderTemplates()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Box>
            )}

            {activeTab === 1 && (
                <Box>
                    <Card sx={{ mt: 2 }}>
                        <CardHeader title="Email Sequences" subheader="Create and manage email sequences" />
                        <CardContent>
                            <form onSubmit={handleSubmitSequence((data) => handleSubmit(data, 'sequence'))}>
                                <Grid container spacing={2}>
                                    {renderSequenceField('name', 'Name')}
                                    {renderSequenceField('description', 'Description', true)}
                                    <Grid item xs={12}>
                                        <Controller
                                            name="userType"
                                            control={controlSequence}
                                            rules={{ required: 'User type is required' }}
                                            render={({ field, fieldState: { error } }) => (
                                                <FormControl fullWidth error={!!error}>
                                                    <InputLabel>User Type</InputLabel>
                                                    <Select {...field} label="User Type">
                                                        {userTypeOptions.map((option) => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {error && <FormLabel error>{error.message}</FormLabel>}
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">Sequence Steps</Typography>
                                        {watchSequence('steps')?.map((_, index) => renderSequenceStepForm(index))}
                                        <Button
                                            variant="outlined"
                                            onClick={handleAddStep}
                                            sx={{ mt: 2 }}
                                        >
                                            Add Step
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary">
                                            Save Sequence
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                    <Card sx={{ mt: 2 }}>
                        <CardHeader title="Sequence List" />
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>User Type</TableCell>
                                            <TableCell>Steps</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderSequences()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Box>
            )}

            <Dialog open={openUserDialog} onClose={() => setOpenUserDialog(false)}>
                <DialogTitle>Select Test Users</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose users to send test emails to.
                    </DialogContentText>
                    {loading ? (
                        <Typography>Loading users...</Typography>
                    ) : (
                        <FormGroup>
                            {emailingServicesUsers.map((user) => (
                                <FormControlLabel
                                    key={user._id}
                                    control={
                                        <Checkbox
                                            checked={selectedUsers.includes(user._id)}
                                            onChange={() => handleUserSelectionChange(user._id)}
                                        />
                                    }
                                    label={user.email}
                                />
                            ))}
                        </FormGroup>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUserDialog(false)}>Cancel</Button>
                    <Button onClick={() => {
                        // Implement sending test emails logic here
                        setOpenUserDialog(false);
                        setSnackbar({ open: true, message: 'Test emails sent successfully', severity: 'success' });
                    }} color="primary">
                        Send Test Emails
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openHistoryDialog} onClose={() => setOpenHistoryDialog(false)}>
                <DialogTitle>Email Send History</DialogTitle>
                <DialogContent>
                    {historyLoading ? (
                        <Typography>Loading history...</Typography>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sent To</TableCell>
                                        <TableCell>Sent At</TableCell>
                                        <TableCell>Trigger Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {emailHistory.map((record) => (
                                        <TableRow key={record._id}>
                                            <TableCell>{record.userEmail}</TableCell>
                                            <TableCell>{new Date(record.sentAt).toLocaleString()}</TableCell>
                                            <TableCell>{record.triggerType}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenHistoryDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default EmailTemplatePage;
