//src/frontend/PracticeAreasPriority.js
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
  
  const PracticeAreasPriority = () => {
    const [searches, setSearches] = useState([]);
    const [practiceAreaCounts, setPracticeAreaCounts] = useState({});
    const [searchesByDate, setSearchesByDate] = useState({});
    const [totalSearches, setTotalSearches] = useState(0);
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [openTables, setOpenTables] = useState({ dates: true, areas: true });
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState({ message: '', type: '' });
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const [searchesResponse, categoriesResponse] = await Promise.all([
            axios.get('/api/searches'),
            axios.get('/api/categories'),
          ]);
  
          setSearches(searchesResponse.data);
          setCategories(categoriesResponse.data);
          setTotalSearches(searchesResponse.data.length);
  
          const [searchesByDate, practiceAreaCounts] = processData(searchesResponse.data, categoriesResponse.data);
          setSearchesByDate(searchesByDate);
          setPracticeAreaCounts(practiceAreaCounts);
        } catch (err) {
          console.error('Error fetching data:', err.message);
        }
      };
  
      fetchData();
    }, []);
  
    const processData = (searchesData, categoriesData) => {
      const searchesByDate = {};
      const practiceAreaCounts = {};
  
      searchesData.forEach((search) => {
        const formattedDate = new Date(search.createdAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
  
        searchesByDate[formattedDate] = (searchesByDate[formattedDate] || 0) + 1;
  
        categoriesData.forEach((category) => {
          if (search.openAiResponse && search.openAiResponse.includes(category.name)) {
            practiceAreaCounts[category.name] = (practiceAreaCounts[category.name] || 0) + 1;
          }
        });
      });
  
      return [searchesByDate, practiceAreaCounts];
    };
  
    const toggleTable = (table) => {
      setOpenTables((prev) => ({ ...prev, [table]: !prev[table] }));
    };
  
    const handleSort = (key) => {
      let direction = 'asc';
      if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
      }
      setSortConfig({ key, direction });
    };
  
    const sortedSearchesByDate = Object.keys(searchesByDate)
      .filter((date) => date.includes(searchQuery))
      .sort((a, b) => {
        if (sortConfig.key === 'date') {
          return sortConfig.direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
        }
        return 0;
      })
      .reduce((acc, key) => ({ ...acc, [key]: searchesByDate[key] }), {});
  
    const sortedPracticeAreaCounts = Object.keys(practiceAreaCounts)
      .filter((area) => area.includes(searchQuery))
      .sort((a, b) => {
        if (sortConfig.key === 'area') {
          return sortConfig.direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
        } else if (sortConfig.key === 'count') {
          return sortConfig.direction === 'asc'
            ? practiceAreaCounts[a] - practiceAreaCounts[b]
            : practiceAreaCounts[b] - practiceAreaCounts[a];
        }
        return 0;
      })
      .reduce((acc, key) => ({ ...acc, [key]: practiceAreaCounts[key] }), {});
  
      const handleSendReport = () => {
        // Determine the date with the most searches
        const mostSearchesDate = Object.keys(sortedSearchesByDate).reduce((maxDate, date) => 
          sortedSearchesByDate[date] > (sortedSearchesByDate[maxDate] || 0) ? date : maxDate, 
          ''
        );
        const mostSearchesCount = sortedSearchesByDate[mostSearchesDate];
      
        // Construct the report data
        const reportData = {
          totalSearches,
          mostSearchesDate: mostSearchesDate,
          mostSearchesCount: mostSearchesCount,
          practiceAreas: Object.keys(sortedPracticeAreaCounts).map((area) => ({
            area,
            count: sortedPracticeAreaCounts[area],
          })),
        };
      
        // Log the values for debugging
        console.log('Date with Most Searches:', reportData.mostSearchesDate);
        console.log('Number of Searches on Most Active Date:', reportData.mostSearchesCount);
      
        // Send the report
        axios.post('/api/send-report', { email, reportData })
          .then(() => {
            alert('Report sent successfully');
            setEmailModalOpen(false);
            setEmail('');
          })
          .catch((error) => {
            console.error('Error sending report:', error);
            alert('Failed to send report');
          });
      };
  
    return (
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Practice Areas Demand Analysis
        </Typography>
  
        {feedback.message && (
          <Alert severity={feedback.type} onClose={() => setFeedback({ message: '', type: '' })}>
            {feedback.message}
          </Alert>
        )}
  
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6">Total Searches</Typography>
                <Typography variant="h3">{totalSearches}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
  
        <Box mt={3} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            Searches Aggregated by Date
          </Typography>
          <IconButton onClick={() => toggleTable('dates')}>
            {openTables.dates ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        {openTables.dates && (
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => handleSort('date')}>
                    <b>Date</b>
                    {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                  </TableCell>
                  <TableCell>
                    <b>Number of Searches</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(sortedSearchesByDate).map((date) => (
                  <TableRow key={date}>
                    <TableCell>{date}</TableCell>
                    <TableCell>{sortedSearchesByDate[date]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
  
        <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            Practice Area Appearance in Searches
          </Typography>
          <IconButton onClick={() => toggleTable('areas')}>
            {openTables.areas ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        {openTables.areas && (
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => handleSort('area')}>
                    <b>Practice Area</b>
                    {sortConfig.key === 'area' && (sortConfig.direction === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                  </TableCell>
                  <TableCell onClick={() => handleSort('count')}>
                <b>Number of Mentions</b>
                    {sortConfig.key === 'count' && (sortConfig.direction === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                  </TableCell>
                  </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(sortedPracticeAreaCounts).map((practiceArea) => (
            <TableRow key={practiceArea}>
              <TableCell>{practiceArea}</TableCell>
              <TableCell>{sortedPracticeAreaCounts[practiceArea]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )}

  <Box mt={4}>
    <Button
      variant="contained"
      color="primary"
      onClick={() => setEmailModalOpen(true)}
    >
      On-Demand Report
    </Button>
  </Box>

  <Modal
    open={emailModalOpen}
    onClose={() => setEmailModalOpen(false)}
    aria-labelledby="email-modal-title"
    aria-describedby="email-modal-description"
  >
    <Box p={3} bgcolor="background.paper" style={{ margin: 'auto', maxWidth: 500, top: '30%', position: 'relative' }}>
      <Typography id="email-modal-title" variant="h6" gutterBottom>
        Enter Email to Send Report
      </Typography>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleSendReport} style={{ marginTop: '20px' }}>
        Send
      </Button>
    </Box>
  </Modal>
</Box>
);
};

export default PracticeAreasPriority;