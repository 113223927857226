//src/frontend/Schools.js
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Search, Sort } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './Schools.css';

const Schools = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState({});
  const [newSchoolData, setNewSchoolData] = useState({ name: '', nativeName: '', code: '' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/schools');
      if (Array.isArray(response.data)) {
        setData(response.data);
      } else {
        setError('Data is not an array');
      }
    } catch (err) {
      setError(`Error fetching data: ${err.message}`);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setEditMode(false);
    setNewSchoolData({ name: '', nativeName: '', code: '' });
  };

  const openEditModal = (school) => {
    setEditMode(true);
    setSelectedSchool(school);
    setNewSchoolData({ name: school.name, nativeName: school.nativeName, code: school.code });
    setShowModal(true);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/schools/${selectedSchool._id}`, newSchoolData);
      fetchData();
      closeModal();
    } catch (err) {
      setError(`Error updating data: ${err.message}`);
    }
  };

  const openAddModal = () => {
    setEditMode(false);
    setNewSchoolData({ name: '', nativeName: '', code: '' });
    setShowModal(true);
  };

  const handleAddNew = async () => {
    try {
      await axios.post('/api/schools', newSchoolData);
      fetchData();
      closeModal();
    } catch (err) {
      setError(`Error adding data: ${err.message}`);
    }
  };

  const openDeleteDialog = (school) => {
    setSelectedSchool(school);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/schools/${selectedSchool._id}`);
      fetchData();
      closeDeleteDialog();
    } catch (err) {
      setError(`Error deleting school: ${err.message}`);
    }
  };

  const handleMenuClick = (event, school) => {
    setSelectedSchool(school);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    const sortedData = [...data].sort((a, b) => {
      return sortOrder === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    });
    setData(sortedData);
    setSortOrder(newOrder);
  };

  const filteredData = data.filter((school) =>
    school.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box className="schools" p={2}>
      <Typography variant="h4" gutterBottom>
        Schools
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button variant="contained" color="primary" onClick={openAddModal}>
          Add New School
        </Button>
        <TextField
          variant="outlined"
          placeholder="Search Schools"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          startIcon={<Sort />}
          onClick={handleSort}
        >
          Sort by Name ({sortOrder})
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Native Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((school, index) => (
              <TableRow key={index}>
                <TableCell>{school.name}</TableCell>
                <TableCell>{school.nativeName}</TableCell>
                <TableCell>{school.code}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleMenuClick(event, school)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => openEditModal(selectedSchool)}>Edit</MenuItem>
                    <MenuItem onClick={() => openDeleteDialog(selectedSchool)}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={showModal} onClose={closeModal}>
        <Box
          className="modal-content"
          style={{
            backgroundColor: '#fefefe',
            margin: 'auto',
            padding: '20px',
            border: '1px solid #888',
            width: '80%',
            maxWidth: '800px',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            maxHeight: '80%',
          }}
        >
          <Typography variant="h6">{editMode ? 'Edit School' : 'Add New School'}</Typography>
          <TextField
            label="School Name"
            value={newSchoolData.name}
            onChange={(e) => setNewSchoolData({ ...newSchoolData, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Native Name"
            value={newSchoolData.nativeName}
            onChange={(e) => setNewSchoolData({ ...newSchoolData, nativeName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code"
            value={newSchoolData.code}
            onChange={(e) => setNewSchoolData({ ...newSchoolData, code: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={editMode ? handleUpdate : handleAddNew}>
              {editMode ? 'Update' : 'Add'}
            </Button>
            <Button variant="outlined" onClick={closeModal} style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this school?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Schools;
