//src/frontend/EmailSettings.js
import {
    Box, Button, FormControl,
    Grid, Switch, TextField, Typography
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const EmailSettings = () => {
    const [enabled, setEnabled] = useState(false);
    const [emails, setEmails] = useState([]);
    const [newEmail, setNewEmail] = useState('');
    const [time, setTime] = useState('07:00');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [reportDate, setReportDate] = useState('');
    const [onDemandEmail, setOnDemandEmail] = useState('');
    const [onDemandSuccessMessage, setOnDemandSuccessMessage] = useState('');
    const [onDemandErrorMessage, setOnDemandErrorMessage] = useState('');

    useEffect(() => {
        fetchEmailSettings();
    }, []);

    const fetchEmailSettings = async () => {
        try {
            const response = await axios.get('/api/settings/email-settings');
            const { enabled, emails, time } = response.data;
            setEnabled(enabled);
            setEmails(emails);
            setTime(time);
        } catch (err) {
            setError('Failed to load settings.');
        }
    };

    const handleAddEmail = () => {
        if (newEmail) {
            setEmails([...emails, newEmail]);
            setNewEmail('');
        }
    };

    const handleRemoveEmail = (index) => {
        const updatedEmails = emails.filter((_, i) => i !== index);
        setEmails(updatedEmails);
    };

    const handleSubmit = async () => {
        try {
            const payload = { enabled, emails, time };
            await axios.put('/api/settings/email-settings', payload);
            setSuccessMessage('Settings updated successfully.');
        } catch (err) {
            setError('Failed to update settings.');
        }
    };

    const handleSendOnDemandReport = async () => {
        try {
            const payload = { date: reportDate, email: onDemandEmail };
            await axios.post('/api/settings/send-on-demand-report', payload);
            setOnDemandSuccessMessage('On-demand report sent successfully.');
            setOnDemandErrorMessage('');
        } catch (err) {
            setOnDemandErrorMessage('Failed to send on-demand report.');
            setOnDemandSuccessMessage('');
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>Email Settings</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <Typography>Email Sending</Typography>
                        <Switch
                            checked={enabled}
                            onChange={() => setEnabled(!enabled)}
                            color="primary"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Typography>Emails</Typography>
                    <TextField
                        label="Add Email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        fullWidth
                    />
                    <Button onClick={handleAddEmail} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                        Add Email
                    </Button>
                    <Box mt={2}>
                        <Typography>Current Email List:</Typography>
                        {emails.map((email, index) => (
                            <Box key={index} display="flex" justifyContent="space-between" mt={1}>
                                <Typography>{email}</Typography>
                                <Button variant="outlined" color="secondary" onClick={() => handleRemoveEmail(index)}>
                                    Remove
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography>Send Time</Typography>
                    <TextField
                        type="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        fullWidth
                    />
                </Grid>

                {error && <Typography color="error">{error}</Typography>}
                {successMessage && <Typography color="primary">{successMessage}</Typography>}

                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Save Settings
                    </Button>
                </Grid>

                {/* On-demand report section */}
                <Grid item xs={12} mt={4}>
                    <Typography variant="h5">Send On-Demand Report</Typography>
                    <TextField
                        label="Select Date"
                        type="date"
                        value={reportDate}
                        onChange={(e) => setReportDate(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Recipient Email"
                        value={onDemandEmail}
                        onChange={(e) => setOnDemandEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        onClick={handleSendOnDemandReport}
                        variant="contained"
                        color="secondary"
                        style={{ marginTop: '10px' }}
                    >
                        Send On-Demand Report
                    </Button>
                    {onDemandSuccessMessage && <Typography color="primary" mt={2}>{onDemandSuccessMessage}</Typography>}
                    {onDemandErrorMessage && <Typography color="error" mt={2}>{onDemandErrorMessage}</Typography>}
                </Grid>
            </Grid>
        </Box>
    );
};

export default EmailSettings;