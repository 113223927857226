//src/frontend/ChartPage.js
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the hook
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

const ChartPage = ({ endpoint }) => {
  const [chartData, setChartData] = useState([]);
  const [comparisonData, setComparisonData] = useState([]);
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [compare, setCompare] = useState(false);
  const [comparisonType, setComparisonType] = useState('lastMonth');
  const [totalCurrent, setTotalCurrent] = useState(0);
  const [totalComparison, setTotalComparison] = useState(0);
  const [userType, setUserType] = useState('All');
  const navigate = useNavigate(); // Use the hook

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/${endpoint}`);
        let data = response.data.map(item => ({
          ...item,
          createdAt: new Date(item.createdAt)
        }));

        // Only filter by user type if the endpoint is 'users'
        if (endpoint === 'users' && userType !== 'All') {
          data = data.filter(item => item.userType === userType);
        }

        const filteredData = filterDataByDateRange(data, startDate, endDate);
        const formattedData = formatChartData(filteredData);
        setChartData(formattedData);
        setTotalCurrent(filteredData.length);

        if (compare) {
          const comparisonData = calculateComparisonData(data, startDate, endDate, comparisonType);
          setComparisonData(comparisonData.data);
          setTotalComparison(comparisonData.total);
        } else {
          setComparisonData([]);
          setTotalComparison(0);
        }
      } catch (err) {
        setError(`Error fetching data: ${err.message}`);
      }
    };

    fetchData();
  }, [endpoint, startDate, endDate, compare, comparisonType, userType]);

  const filterDataByDateRange = (data, start, end) => {
    return data.filter(item => dayjs(item.createdAt).isBetween(start, end, null, '[]'));
  };

  const formatChartData = (data) => {
    const aggregatedData = data.reduce((acc, item) => {
      const date = item.createdAt.toLocaleDateString();
      acc[date] = acc[date] || { createdAt: date, count: 0 };
      acc[date].count += 1;
      return acc;
    }, {});
    return Object.values(aggregatedData);
  };

  const calculateComparisonData = (data, start, end, comparisonType) => {
    let prevStart, prevEnd;

    if (comparisonType === 'lastMonth') {
      prevStart = start.subtract(1, 'month');
      prevEnd = end.subtract(1, 'month');
    } else if (comparisonType === 'lastYear') {
      prevStart = start.subtract(1, 'year');
      prevEnd = end.subtract(1, 'year');
    }

    const comparisonData = data.filter(item => dayjs(item.createdAt).isBetween(prevStart, prevEnd, null, '[]'));
    return {
      data: formatChartData(comparisonData),
      total: comparisonData.length,
    };
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box p={2}>
        <Typography variant="h4" gutterBottom>
          {endpoint.charAt(0).toUpperCase() + endpoint.slice(1)} Chart
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          {/* Render the User Type dropdown only for the Users chart */}
          {endpoint === 'users' && (
            <Grid item>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="user-type-label">User Type</InputLabel>
                <Select
                  labelId="user-type-label"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  label="User Type"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="INDIVIDUAL">Individual</MenuItem>
                  <MenuItem value="BUSINESS">Business</MenuItem>
                  <MenuItem value="PROVIDER">Provider</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={compare} onChange={(e) => setCompare(e.target.checked)} />}
              label="Compare with Previous Period"
            />
          </Grid>
          {compare && (
            <Grid item>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="comparison-type-label">Comparison Type</InputLabel>
                <Select
                  labelId="comparison-type-label"
                  value={comparisonType}
                  onChange={(e) => setComparisonType(e.target.value)}
                  label="Comparison Type"
                >
                  <MenuItem value="lastMonth">Vs Last Month</MenuItem>
                  <MenuItem value="lastYear">Vs Previous Year</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Box mt={2}>
          <Typography variant="h6">
            Total for Selected Period: {totalCurrent}
          </Typography>
          {compare && (
            <Typography variant="h6">
              Total for Comparison Period: {totalComparison}
            </Typography>
          )}
        </Box>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="createdAt" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="count" stroke="#8884d8" name="Current Period" />
            {compare && <Line type="monotone" dataKey="count" data={comparisonData} stroke="#82ca9d" name={`Comparison (${comparisonType === 'lastMonth' ? 'Last Month' : 'Last Year'})`} />}
          </LineChart>
        </ResponsiveContainer>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/prediction-charts/${endpoint}`)}  // Navigate to prediction charts
          >
            View Predictions
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default ChartPage;