//src/frontend/UsersManagement.js
import React, { useState, useEffect } from 'react';
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Typography, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import axios from 'axios';
import './UsersManagement.css';

const UsersManagement = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [closeDialogOpen, setCloseDialogOpen] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/api/users');
            setUsers(response.data);
        } catch (err) {
            setError(`Error fetching users: ${err.message}`);
        }
    };

    const openEditModal = (user) => {
        setSelectedUser(user);
        setUnsavedChanges(false);  // Reset unsaved changes state
        setShowModal(true);
    };

    const closeModal = () => {
        if (unsavedChanges) {
            setCloseDialogOpen(true);  // Open confirmation dialog if there are unsaved changes
        } else {
            setShowModal(false);
        }
    };

    const handleFieldChange = (field, value) => {
        setSelectedUser((prev) => ({ ...prev, [field]: value }));
        setUnsavedChanges(true);  // Mark that there are unsaved changes
    };

    const handleUpdate = async () => {
        try {
            await axios.put(`/api/users/${selectedUser._id}`, selectedUser);
            fetchUsers();
            setShowModal(false);
            setUnsavedChanges(false);
        } catch (err) {
            setError(`Error updating user: ${err.message}`);
        }
    };

    const confirmCloseModal = () => {
        setShowModal(false);
        setCloseDialogOpen(false);
    };

    const handleSaveChanges = () => {
        handleUpdate();
        setSaveDialogOpen(false);
    };

    return (
        <Box className="users-management-container">
            <Typography variant="h4">User Management</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user._id}>
                                <TableCell>{user.firstName} {user.lastName}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.userType}</TableCell> {/* Show user type */}
                                <TableCell>
                                    <Button variant="outlined" onClick={() => openEditModal(user)}>
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal for editing user */}
            <Modal open={showModal} onClose={closeModal}>
                <Box className="modal-content">
                    <Typography variant="h6">Edit User</Typography>
                    <TextField
                        label="First Name"
                        value={selectedUser?.firstName || ''}
                        onChange={(e) => handleFieldChange('firstName', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Last Name"
                        value={selectedUser?.lastName || ''}
                        onChange={(e) => handleFieldChange('lastName', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        value={selectedUser?.email || ''}
                        onChange={(e) => handleFieldChange('email', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="user-type-label">User Type</InputLabel>
                        <Select
                            labelId="user-type-label"
                            value={selectedUser?.userType || ''}
                            onChange={(e) => handleFieldChange('userType', e.target.value)}
                        >
                            <MenuItem value="PROVIDER">Provider</MenuItem>
                            <MenuItem value="CLIENT">Client</MenuItem>
                            <MenuItem value="ADMIN">Admin</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Phone Number"
                        value={selectedUser?.phoneNumber || ''}
                        onChange={(e) => handleFieldChange('phoneNumber', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Address"
                        value={selectedUser?.address || ''}
                        onChange={(e) => handleFieldChange('address', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    {/* Add other fields as needed */}
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setSaveDialogOpen(true)}
                        >
                            Save Changes
                        </Button>
                        <Button variant="outlined" onClick={closeModal} style={{ marginLeft: '10px' }}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Save confirmation dialog */}
            <Dialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                aria-labelledby="save-confirmation-dialog"
            >
                <DialogTitle id="save-confirmation-dialog">Confirm Save</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to save the changes made to this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSaveDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveChanges} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Close without saving confirmation dialog */}
            <Dialog
                open={closeDialogOpen}
                onClose={() => setCloseDialogOpen(false)}
                aria-labelledby="close-confirmation-dialog"
            >
                <DialogTitle id="close-confirmation-dialog">Unsaved Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have unsaved changes. Are you sure you want to close without saving?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCloseDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmCloseModal} color="primary" autoFocus>
                        Close without Saving
                    </Button>
                </DialogActions>
            </Dialog>

            {error && <Typography color="error">{error}</Typography>}
        </Box>
    );
};

export default UsersManagement;
